<template>
	<!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="paramsTransferencia.estatus"
    persistent
    max-width="1000"
  >
		<v-card class="elevation-0">
			<v-card-title primary-title>
				<v-spacer></v-spacer>
			  Transferencia de saldos a favor
				<v-spacer></v-spacer>
			</v-card-title>

			<!-- Contenido de las cards -->
			<v-card-text class="black--text">

				<v-row justify="space-around">
					<v-col cols="12" md="5">
						<v-row>
				      <v-col cols="12" v-if="alumno">
				        <span><b>Alumno: </b>{{ alumno.alumno }} <br/></span>
				        <span><b>Matricula: </b>{{ alumno.matricula }} <br/></span>
				        <span><b>Grupo: </b>{{ alumno.grupo }} <br/></span>
				      </v-col>

				      <!-- Div solo para generar el espacio del botón faltante de calcular costo -->
				      <v-col cols="12" class="mt-9"></v-col>
				     
							<v-col cols="12">
								<v-list class="transparent" dense>
						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              Precio inicial:
				            </v-list-item-title>

						        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
						          <b>$ {{ alumno.precio }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0" v-if="alumno.valor_descuento">
						        <v-list-item-title align="end" class="pr-2">
				              Descuento:
				            </v-list-item-title>

						        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
						          <b>$ {{ alumno.valor_descuento }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider v-if="alumno.valor_descuento"></v-divider>


				          <v-list-item class="pa-0" v-if="alumno.pagado">
				            <v-list-item-title align="end" class="pr-2">
				              Total pagado:
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
				              <b>$ {{ alumno.pagado }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="alumno.pagado"></v-divider>

				          <v-list-item class="pa-0" v-if="solcitaFactura">
				            <v-list-item-title align="end" class="pr-2">
				              <b>IVA:</b>
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="text-h6 black--text">
				              <b>$ {{ iva }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="solcitaFactura"></v-divider>

						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Total a pagar:</b>
				            </v-list-item-title>
						        
				            <v-list-item-subtitle class="text-h6 black--text">
						          <b>$ {{  alumno.precio_grupo_con_descuento_x_alumno }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0" v-if="alumno.adeudo">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Adeudo:</b>
				            </v-list-item-title>
						        
				            <v-list-item-subtitle class="red--text font-weight-semibold text-h6 black--text">
						          <b>$ {{ alumno.adeudo }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
						    </v-list>

						    <div>
						    	<v-btn 
						    		color="green" 
						    		dark 
						    		small 
						    		class="mr-2 text-capitalize" 
						    		v-if="( parseFloat( alumno.pagado ) <= alumno.precio_con_descuento ) "
						    		tile
						    	>
						    		<v-icon small left>mdi-check-circle</v-icon>
						    		Liquidado
						    	</v-btn>
						    </div>
							</v-col>

							<v-col cols="12" md="6" v-if="tipo_pago && tipo_pago != 1" class="pa-0">
				        <v-card-text align="center" class="pa-0">
				          <div v-if="!file">
				            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
				              <div class="dropZone-info" @drag="onChange">
				                <span class="fa fa-cloud-upload dropZone-title"></span>
				                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
				                <div class="dropZone-upload-limit-info">
				                  <div>Extensión: png, jpg, jpeg, svg</div>
				                  <div>Tamaño máximo: 10 MB</div>
				                </div>
				              </div>
				              <input type="file" @change="onChange">
				            </div>
				          </div>

				          <v-btn  v-else color="red" class="mb-2" dense @click="removeFile" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
				          <v-img :src="vistaPrevia.url" v-if="vistaPrevia" contain max-height="300"/>

				          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isPracticaja">
				            <strong>PRACTICAJA</strong>
				          </span>
				          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isTransferencia">
				            <strong>TRANSFERENCIA {{ banco }}</strong>
				          </span>
				          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isSeven">
				            <strong>7-SEVEN</strong>
				          </span>
				          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isOXXO">
				            <strong>OXXO</strong>
				          </span>
				          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isWeb">
				            <strong>PAGO DESDE WEB</strong>
				          </span>
				          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isTarjeta">
				            <strong>TARJETA</strong>
				          </span>
				          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isDirectoBancoBBVA">
				            <strong>PAGO EN BANCO BBVA</strong>
				          </span>
				          <span small dark color="primary" class="font-weight-semibold text-body-1 text-center" v-if="isDirectoBANREGIO">
				            <strong>PAGO EN BANCO BANREGIO</strong>
				          </span>
				        </v-card-text>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols="12" md="5">
						<v-row>
				      <v-col cols="12" v-if="alumno">
				        <span><b>Alumno: </b>{{ alumno.alumno }} <br/></span>
				        <span><b>Matricula: </b>{{ alumno.matricula }} <br/></span>
				        <v-autocomplete
		              filled
		              dense
		              single-line
		              clearable
		              v-model="grupo"
		              :items="grupos"
		              label="Selecciona un grupo"
		              persistent-hint
		              hide-details
		              return-object
		              item-text="grupo"
		              item-value="id_grupo"
		            >
		            </v-autocomplete>
				      </v-col>
				     
							<v-col cols="12">
				        <v-btn 
				          color="orange"
				          dark
				          class="mb-6 mt-2"
				          tile
				          small
				          @click="getPrecioPagar()"
				        >
				          <v-icon left small>mdi-cash-lock-open</v-icon>
				          Calcular costo
				        </v-btn>
				 
								<v-list class="transparent" dense>
						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              Precio inicial:
				            </v-list-item-title>

						        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
						          <b>$ {{ precio_inicial }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0" v-if="descuento">
						        <v-list-item-title align="end" class="pr-2">
				              Descuento:
				            </v-list-item-title>

						        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
						          <b>$ {{ descuento }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider v-if="descuento"></v-divider>


				          <v-list-item class="pa-0" v-if="total_pagado">
				            <v-list-item-title align="end" class="pr-2">
				              Total pagado:
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
				              <b>$ {{ total_pagado }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="total_pagado"></v-divider>

				          <v-list-item class="pa-0" v-if="solcitaFactura">
				            <v-list-item-title align="end" class="pr-2">
				              <b>IVA:</b>
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="text-h6 black--text">
				              <b>$ {{ iva }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="solcitaFactura"></v-divider>

						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Total a pagar:</b>
				            </v-list-item-title>
						        
				            <v-list-item-subtitle class="text-h6 black--text">
						          <b>$ {{  total_a_pagar }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0" v-if="adeudo">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Adeudo:</b>
				            </v-list-item-title>
						        
				            <v-list-item-subtitle class="red--text font-weight-semibold text-h6 black--text">
						          <b>$ {{ adeudo }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
						    </v-list>

						    <div>
						    	<v-btn 
						    		color="green" 
						    		dark 
						    		small 
						    		class="mr-2 text-capitalize" 
						    		v-if="adeudo <= 0 "
						    		tile
						    	>
						    		<v-icon small left>mdi-check-circle</v-icon>
						    		Liquidado
						    	</v-btn>
						    </div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="text-center text-h5">
						<v-icon left color="red">mdi-arrow-left-bold</v-icon>
						Transferir: <b>{{ transferirFormat }}</b>
						<v-icon right color="red">mdi-arrow-right-bold</v-icon>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions class="mt-6">
	      <v-btn
	        color="black"
	        dark
	        @click="paramsTransferencia.estatus = false"
	        tile
	        small
	        class="text-capitalize"
	      >
	        <v-icon left small>mdi-cancel</v-icon>
	        cancelar
	      </v-btn>
	      <v-spacer></v-spacer>

	      <v-btn
	        color="green"
	        small
	        dark
	        class="text-capitalize"
	        @click="preProcesarPago()"
	        tile
	      >
	        <v-icon left small>mdi-cash-check</v-icon>
	        Procesar
	      </v-btn>
	    </v-card-actions>

	    <!-- DIALOGO PARA CONFIRMAR EL CORREO DEL ALUMNO -->
	    <v-dialog
	      v-model="dialogConfirmaCorreo"
	      persistent
	      max-width="500"
	    >
	      <v-card>
	        <v-card-title class="text-h6">
	          Correo a donde se enviará el recibo de pago
	        </v-card-title>
	        <v-card-text>
	          <v-text-field
	            name="name"
	            label="label"
	            id="id"
	            v-model="alumno.email"
	            filled
	            v-if="alumno"
	          ></v-text-field>
	        </v-card-text>
	        <v-card-actions>
	          <v-btn
	            color="red"
	            @click="dialogConfirmaCorreo = false, $emit('terminarProceso')"
	            rounded
	            dark
	            small
	          >
	            Cancelar
	          </v-btn>
	          <v-spacer></v-spacer>
	          <v-btn
	            color="green"
	            @click="enviarComprobante()"
	            rounded
	            dark
	            small
	          >
	            Actualizar y enviar
	          </v-btn>
	        </v-card-actions>
	      </v-card>
	    </v-dialog>
		</v-card>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta              from '@/components/alertas/Alerta.vue'
  import carga               from '@/components/alertas/carga.vue';

  import validarErrores      from '@/mixins/validarErrores'
  import funcionesExcel      from '@/mixins/funcionesExcel'

  const convertir = require('numero-a-letras');
  import { jsPDF } from "jspdf";

  const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

  	props:[
  		'paramsTransferencia',
  		'alumno'
  	],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      precio_inicial:0,
			descuento:0,
			total_a_pagar:0,

			tipo_pago: 1,
			sucursal:null,
			total_pagado:0,

			label:'',
      monto:0,
      pago_anterior:0,

      saldoFavor: 0,
      tieneSaldoFavor: false,

      dialogConfirmaCorreo:'',

      beca: null,
      pagoRealizao:0,

      pagosCursos:[],

      myState2: {
        pdf:         null,
        currentPage: 1,
        zoom:        2
      },
      solcitaFactura: 1,
      saldoFactura: 0,
      descuentoRecomendados:[],
      descRecomienda:0,
      descuentosEspeciales:[],
      iva: 0,
      grupos:[],
      grupo: null,
      transferirFormat: 0,
      transferir: 0,
      nuevoSaldoFavor: 0,
    }),

    computed: {

    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
      
      adeudo( ){
        const restante = (parseFloat(this.total_a_pagar) - parseFloat(this.total_pagado) )
        if( restante > 0 )
          return restante.toFixed(2)
        else
          return 0
      },
    },

    watch: {
      grupo( value ){ if( value ){ this.getPrecioPagar() } }
    },

    async created () {
    	console.log( this.alumno )
      this.total_pagado = ( parseFloat( this.alumno.precio_grupo_con_descuento_x_alumno ) - parseFloat( this.alumno.pagado ) ) * -1
      this.transferirFormat  =  new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.total_pagado )

	    await this.getGruposDisponibles( )
    	this.grupo = await this.getGrupoSiguiente( ).then( response => response )
    	await this.getPrecioPagar()
    },


    methods: {

    	getGrupoSiguiente( ){
    		return new Promise(( resolve, reject ) => {

    			// Creando el payload para encontrar el siguiente ciclo
    			const payload = {
	      		id_alumno: this.alumno.id_alumno,
	      		escuela  : this.alumno.unidad_negocio
	      	}

	      	// Buscamos el siguiente grupo
	        return this.$http.post('inscripciones.grupo.alumno', payload).then( response => {
	        	this.cargar = false
	        	
	        	if( response.data.grupoSiguiente ){
            	resolve( response.data.grupoSiguiente )
	        	}else{
	        		resolve( null )
	        	}

	        }).catch( error =>{
	          reject( error )
	        }).finally( () => { this.cargar = false })
        })
    	},

    	getGruposDisponibles( ) {
        this.cargar = true
        this.grupos = []

        return this.$http.get('grupos.disponibles/' + this.alumno.unidad_negocio ).then(response=>{
          this.cargar = false
          this.grupos = response.data

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getPrecioPagar( ) {

      	if( !this.grupo ){ return this.validarErrorDirecto('Favor de seleccionar un grupo') }

      	this.cargar = true

      	const payload = {
      		id_alumno:        this.alumno.id_alumno,
      		id_empleado:      null,
      		tipoAlumno:       2,
      		empleado_activo:  0,
      		id_curso:         this.grupo.id_curso,
      		grupoSiguiente:   this.grupo,
          hermanos:         false,
          fechapago:        this.alumno.fecha_alta.substr(0,10),
          factura:          false,
          unidad_negocio:   this.alumno.unidad_negocio
      	}

        return this.$http.post('inscripciones.calcular.precio', payload).then(response=>{

        	this.precio_inicial         = response.data.precio_inicial
					this.descuento              = response.data.descuento
					this.total_a_pagar          = response.data.total_a_pagar
					this.beca                   = response.data.beca 
          this.pago_anterior          = response.data.pago_anterior 
          this.saldoFavor             = response.data.saldoFavor 
          this.tieneSaldoFavor        = response.data.tieneSaldoFavor 
          this.pagosCursos            = response.data.pagosCursos
          this.descuentoRecomendados  = response.data.descuentoRecomendados
          this.descRecomienda         = response.data.descRecomienda
          this.descuentosEspeciales   = response.data.descuentosEspeciales

        	this.cargar = false
          this.noPago = false
        }).catch( error =>{
          this.noPago = true
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async preProcesarPago( ){
      	this.cargar = true

        // Generamos el payload del pago
      	const payload = {
          adeudo                     : parseFloat( this.adeudo ),
			    base_path                  : null,
			    comentarios                : '',
          descRecomienda             : [],
			    descuento                  : this.descuento,
          descuentoRecomendados      : [],
          descuentosEspeciales       : [],
          guardarCurso               : false,
			    id_alumno                  : this.alumno.id_alumno,
			    id_ciclo                   : this.grupo.id_ciclo,
          id_curso                   : this.grupo.id_curso,
			    id_descuento_pronto_pago   : null,
			    id_forma_pago              : this.tipo_pago,
          id_grupo                   : this.grupo.id_grupo,
			    id_grupo_anterior          : this.alumno.id_grupo_actual,
			    id_plantel_ingreso         : this.getdatosUsuario.id_plantel,
			    id_tipo_descuento          : null,
			    id_tipo_ingreso            : 2,
			    id_tipo_inscripcion        : this.total_pagado >= this.total_a_pagar ? 2 : 3,
			    id_usuario_ultimo_cambio   : this.getdatosUsuario.iderp,
          idbecas                    : this.beca ? this.beca.id : 0,
			    monto_adeudo_grupo         : 0,
			    monto_descuento_grupo      : parseFloat( this.descuento ),
			    monto_pagado_total         : parseFloat( this.total_pagado ),
			    monto_saldo_favor          : parseFloat( this.saldoFavor ),
          nuevoSaldoFavor            : parseFloat( this.nuevoSaldoFavor ),
			    pago_completado_sn         : this.total_pagado >= parseFloat( this.total_a_pagar ) ? 1 : 0,
			    precio_curso               : this.precio_inicial,
			    precio_grupo_con_descuento : ( parseFloat( this.precio_inicial ) - parseFloat( this.descuento ) ) ,
			    precio_grupo_sin_descuento : this.precio_inicial,
          saldoFavor                 : this.saldoFavor,
          solcitaFactura             : this.solcitaFactura,
          tieneSaldoFavor            : this.tieneSaldoFavor,
          texto_completo             : '',
          tipoAlumno                 : 2,
          total_pagado_alumno        : ( parseFloat( this.precio_inicial ) + parseFloat( this.saldoFactura ) ),
			    url_foto                   : '',
			    transferir                 : parseFloat( this.total_pagado )
				}

				this.$http.post('registrar.transferencia', payload ).then( response => {
          // Convertir el monto en texto
          this.pagoRealizao = response.data.ingreso.id
          this.dialogConfirmaCorreo = true

				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
      },

      enviarComprobante( ){
        const payload = {
          folio:                  this.pagoRealizao,
          alumno:                 this.alumno.alumno,
          matricula:              this.alumno.matricula,
          plantel:                this.getdatosUsuario.plantel,
          ciclo:                  this.grupo.ciclo,
          curso:                  this.grupo.curso,
          cantidad_recibida:      this.total_pagado,
          cantidad_letra:         convertir.NumerosALetras(this.total_pagado).toUpperCase(),
          descuento_pronto_pago:  this.descuento,
          adeudo:                 this.adeudo,
          saldo_favor:            this.nuevoSaldoFavor,
          id_alumno:              this.alumno.id_alumno,
          email:                  this.alumno.email,
          unidad_negocio:         this.alumno.unidad_negocio,
          fecha_pago:             this.fechapago,
          recepcion:              this.getdatosUsuario.nombre_completo,
          solcitaFactura:         this.solcitaFactura
        }

        this.cargar = true
        this.$http.post('actualizar.correo.alumno', payload ).then( response => {
          this.validarSuccess(response.data.message)

          this.url = axios.defaults.baseURL + 'recibo-pago/' + this.pagoRealizao + '.pdf'

          window.open( this.url )
          this.grupo = null
          this.paramsTransferencia.estatus = false
        }).catch( error =>{
         this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>